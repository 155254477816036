//default style for tooltips
.tooltip {
  .tooltip-inner {
    font-size: 1.3rem;
    font-weight: 500;
    padding: 8px 12px;
    background: $outlaw-dark;
    overflow-wrap: break-word;
  }
  &.top .tooltip-arrow {
    border-top: 5px solid $outlaw-dark;
  }
  &.bottom .tooltip-arrow {
    border-bottom: 5px solid $outlaw-dark;
  }
  &.left .tooltip-arrow {
    border-left: 5px solid $outlaw-dark;
  }
  &.right .tooltip-arrow {
    border-right: 5px solid $outlaw-dark;
  }

  &.content-left .tooltip-inner {
    text-align: left;
  }
}

.id-tip {
  display: flex;
  max-width: inherit;

  .id {
    overflow: hidden;
    max-width: 80%;
    margin-right: 5px;
    text-overflow: ellipsis;
  }
}

.hide-on-mobile {
  display: none !important;
  @include mediaquery-medium {
    display: flex !important;
  }
}

.fullscreen-preloader {
  // when app first fires up, preloader is horz/vert centered in screen
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auto-deal {
  //payfully and public deals use this right now
  .outer-paper-layout {
    padding-top: 60px;
    @include mediaquery-medium {
      padding-top: 0px;
      margin-top: 38px;
      margin-bottom: 80px;
    }
  }

  // hide strip, only show toggle (if there is one)
  .deal-header {
    background: none;
    .toolbar {
      box-shadow: none;
      border-bottom: none;
    }
  }
}
// think of this as the paper that the deal text sits on
.outer-paper-layout {
  @extend .bigshadow; //only shows on mediaquery-medium
  background-color: $white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  @include mediaquery-medium {
    margin-top: 10px;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }

  @include mediaquery-large {
    max-width: $legal-paper-width;
  }

  // Showing bundle adds 250px to the ideal required total width, plus 20px margin on each side
  // If the screen is narrower, the BundleView will show over top of the document (like filters on contracts list)
  &.show-bundle {
    @include mediaquery-large {
      margin-left: 270px;
    }
    @include mediaquery-xlarge {
      margin-left: calc(
        (100vw - #{$legal-paper-width} - #{$bundle-sidebar-width} - 40px) / 2 + #{$bundle-sidebar-width} + 20px
      );
      margin-right: auto;
    }
  }

  // classes tacked on to .outer-paper-layout class

  &.in-app-deal {
    // when deals are done within Outlaw webapp
    // push down margins because the top bar and deal-steps
    // have to use top instead of margin because margin-top throws off the tooltips
    position: relative;
    top: $mobile-nav-height + $mobile-abc-height;
    padding-bottom: $deal-header-height;
    @include mediaquery-medium {
      top: $top-bar-height-slim + $deal-header-height + 10px;
      padding-bottom: 0;
      margin-bottom: $deal-header-height + $margin-desktop + 50px;
    }
    @include mediaquery-large {
      top: $top-bar-height-slim + $deal-header-height + 30px;
    }
    &.dotvine {
      top: 0;
      margin-top: $top-bar-height-slim;
      min-width: $legal-paper-width;
      margin-bottom: 0;
    }
  }

  // nested inside .outer-paper-layout

  .inside-paper-layout {
    flex: 1;
    width: 100%;

    @include mediaquery-xsmall {
      padding: 30px 15px;
    }

    @include mediaquery-small {
      padding: 30px 15px;
    }

    @include mediaquery-medium {
      padding: 60px 0px;
      max-width: 600px;
    }
  }

  &.timeline {
    min-width: $legal-paper-height;
    max-width: $legal-paper-height;
    min-height: $legal-paper-width;
    max-height: $legal-paper-width;
    padding: 96px;
  }
}

// branding used on summary and contract
.deal-branding {
  display: flex;
  flex-direction: row;

  .org-letterhead {
    flex: 1;
    justify-content: center;
    img {
      width: 100%;
    }
  }

  .org-logo {
    flex: 1;
    @include mediaquery-medium {
      flex: 2;
    }
    img {
      max-width: 160px;
      max-height: 80px;
      width: auto;
    }
  }
  .org-meta {
    flex: 1;
    text-align: right;

    span {
      display: block;
    }
  }
}

.section-title {
  position: relative;
  &:hover {
    .section-menu {
      .dmp-dd-toggle {
        opacity: 1;
      }
    }
  }
}

//override Draft (rich text editor) placholder styling
// used on .template-editor,
.public-DraftEditorPlaceholder-inner {
  color: $gray-3;
  position: absolute;
  pointer-events: none;
}

// SCOPE and PAYMENT actions
$width-of-actions: 150px;
.section-item-actions {
  text-align: right;
  position: absolute;
  width: $width-of-actions;
  top: 20px;
  right: 0px;
  height: 0; // some reason its rendering tall
  @include mediaquery-medium {
    top: 15px;
  }
  button {
    &:last-child {
      margin-left: 3px;
    }
  }
}

//login page variables
$login-page-min-height: 700px;
$login-form-width: 300px;

//needs to be at top level in order to target ONLY the version of logo that appears on /login route
.login-logo {
  width: 120px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.login-page,
.wl-login,
.access-page {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  overflow-y: hidden;
  background-color: $white;
  width: 100%;

  .col1 {
    display: flex;
    min-height: $login-page-min-height;
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: flex-end;

    @include mediaquery-medium {
      flex: 2;
      padding-top: 0;
      display: flex;
      align-items: center;
      overflow-y: hidden;
    }
    .col1-inner {
      max-width: 300px;
      margin: auto;
      text-align: center;
      padding: 50px 0;

      @include mediaquery-medium {
        width: 300px;
        overflow-y: hidden;
      }
      h2 {
        font-size: 2.8rem;
        font-weight: 400;
        letter-spacing: -0.72px;
      }
      .team-logo {
        width: 120px;
        margin-top: 10px;
      }
    }

    .no-deal-access {
      h1 {
        font-size: 35px;
        line-height: 35px;
      }

      hr {
        height: 0.5px;
        background-color: #d1d3d5;
      }

      .current-user {
        font-size: 20px;
        font-weight: bold;
        line-height: 22px;
        margin: 20px 10px;
        overflow-wrap: break-word;
      }

      button {
        font-weight: bold;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        width: 100%;
        padding: 15px;
        margin-top: 20px;

        &:first-child {
          margin-top: 5px;
        }

        .re-login,
        .exit {
          background: linear-gradient(90deg, #6b42c4 0%, #8e54e9 100%);
        }
      }

      .request-terms {
        display: block;
        clear: both;
        text-align: center;
        padding: 30px 0;
        font-size: 1.3rem;
        line-height: 1.5;
        color: $black40;
      }

      .small {
        font-size: 13px;
        overflow-wrap: break-word;
      }
    }
  }

  .col2 {
    display: none;
    background: linear-gradient(45deg, $outlaw-dark 25%, lighten($outlaw-dark, 10%) 100%);
    position: relative;
    @include mediaquery-large {
      min-height: inherit;
      display: flex;
      align-items: center;
      padding: 0;
      flex: 1;
      text-align: left;
    }
    .bg-div {
      background-image: url(/assets/png/promoaside-bg.jpg);
      filter: grayscale(75%);
      opacity: 0.1;

      z-index: 0;
      content: '';
      height: 100vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .inner {
    z-index: 1;
    svg {
      width: 100px;
      height: 100px;
      path {
        fill: $white;
      }
    }
    // background-color: rgba($black100,.10);
    width: 100%;
    text-align: center;
    // min-height: 300px;
    padding: 40px 50px;
    color: white;
    font-size: 1.6rem;
    h3 {
      font-weight: 700;
      margin: 0;
      padding-bottom: 10px;
    }
    a {
      color: $white;
      font-weight: 700;
    }
  }
}

.wl-login {
  .fv-ol-logos {
    height: 35px;
  }
  .no-auth {
    color: $alert;
    margin-bottom: 10px;
  }
  button.login-external {
    width: 100%;
    margin: 10px 0;
  }
  .login-wrapper {
    margin-top: 20px;
  }
  h2 {
    margin-top: 0;
  }
}

//sign in as a guest button
.login-wrapper {
  .guest {
    span {
      display: block;
      padding-bottom: 20px;
      color: $black60;
      a {
        font-size: 1.3rem;
        text-decoration: underline;
      }
    }
    .btn-link {
      font-size: 16px;
      padding: 20px 0;
      font-weight: bold;
      color: $black80;
      &:hover {
        color: $black100;
      }
    }
  }
}

.login-signup-titles {
  padding: 20px 0px 20px 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  h3 {
    margin: 10px 0 10px 0;
  }

  .highlight {
    color: #844ad3;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}

.login-terms {
  display: block;
  clear: both; //in case login error from FB or Google is showing
  text-align: center;
  padding: 30px 0;
  font-size: 1.3rem;
  line-height: 1.5;
  color: $black40;
}

//reusable content
.flex-middle-outer {
  text-align: center;
  width: 100%;

  @include mediaquery-medium {
    flex-direction: column;
    min-height: 600px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-middle-inner {
    width: 90%;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;

    .invite-icon {
      margin-bottom: 20px;
    }
    p {
      @extend .txt_body;
      margin-bottom: 10px;
    }
    .instruction {
      margin-bottom: 30px;
      h1 {
        @extend .txt_headline_1;
        margin: 0;
        padding: 0;
      }
      p {
        margin-top: 20px;
        margin-bottom: 30px;
      }
    }
    hr {
      width: 100%;
      margin: 40px auto;
    }
    .info {
      p {
        color: $black30;
      }
      .learnmore {
        margin-top: 20px;
        @extend .txt_headline_6;
        color: $black30;
      }
      img {
        margin-top: 10px;
        opacity: 0.1;
        width: 80px;
      }
    }
  }
}

//note: also used for variable view/editor
//this should maybe just be our standard popover style
.popover {
  //override default
  //&.bottom { margin-top: 20px; }
  //border-radius: 2px;
  //border: 1px solid $black20;
  //width: 100%;
  @include mediaquery-medium {
    width: 280px;
  }

  &.popover-bart-editing-config {
    .popover-title {
      padding: 15px 15px 0px 15px;
    }

    .radio {
      margin-top: 0px;
      margin-bottom: 5px;
    }
  }

  // Will have to delete once we've completely moved to @dmp/Popover
  .popover-title {
    padding: 15px 45px 15px 15px;
    background-color: transparent;
    border-bottom: 0;
  }

  //deal user style
  &.popover-deal-user {
    margin-bottom: $deal-header-height + 20px;
    //DealUserView popover is beneath the bottom ABC strip in z-index
    //so we need to add corresponding margin to ensure that it can always be made visible via scroll
    .#{$dmp-checkbox} {
      float: left;
      padding-top: 3px;
    }
  }

  &.pop-diff,
  &.pop-delete-section,
  &.pop-link {
    background-color: rgba($outlaw-dark, 0.95);
    border: 0;
    width: max-content;
    padding: 0;
    // just make sure it doesn't go over top bar during scrolling!
    z-index: 200;
    .popover-content {
      padding: 0;
    }
    .arrow {
      opacity: 0.95;
      &:after {
        border-top-color: rgba($outlaw-dark, 0.95);
        border-bottom-color: rgba($outlaw-dark, 0.95);
      }
    }

    .diff-info {
      padding: 10px;
      width: 220px;
      border-bottom: 1px solid $black60;
      .diff-step {
        &:not(:last-child) {
          margin-bottom: 3px;
          padding-bottom: 3px;
          border-bottom: 1px solid $black60;
        }
      }
      .name-action {
        font-size: 1.3rem;
        color: $white;
        .name {
          font-weight: 600;
        }
      }
      .date {
        color: $black20;
        font-size: 1.2rem;
      }
      .pending {
        color: $discuss;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.5rem;
        margin-top: 3px;
      }
    }
    .diff-review {
      justify-content: flex-end;

      button {
        background: none;
        border: none;
        color: $white;
        margin: 0;
        padding: 0;
        font-size: 1.3rem;
        font-weight: 600;
        margin-left: 10px;

        &:hover {
          text-decoration: underline;
        }
        &:active {
          outline: none;
        }
      }

      &.whole-section button {
        width: 140px;
      }

      .diff-border {
        height: 35px;
        border-bottom: 1px solid rgb(102, 102, 102);
      }

      .diff-review-action {
        height: 35px;
        padding-top: 8px;
        padding-right: 15px;
        float: inline-end;
      }

      .diff-all-review {
        display: inline-block;
        float: inline-end;
        padding-right: 15px;
        padding-top: 8px;
        padding-bottom: 8px;

        .diff-review-approve-section {
          float: inline-end;
        }
      }

      .confirm-review-approve-section,
      .confirm-review-approve-all {
        padding-top: 4px;
        display: inline-block;

        .approve-section,
        .approve-all {
          float: inline-end;
          color: $color-green;
          &.disabled {
            color: $gray-2;
          }
        }
        .go-back-diff {
          float: inline-start;
          &.disabled {
            color: $gray-2;
          }
        }
      }
    }

    .diff-restore {
      display: flex;
      flex-direction: row;
      height: 40px;
      justify-content: flex-end;
      padding-right: 15px;
    }

    .confirm-diff {
      border-bottom: none;
      padding-left: 5px;
      .dmp-loader {
        margin: 0px;
        margin-left: 18px;
        margin-bottom: 5px;
        margin-right: -5px;
      }
      &.disabled {
        padding-bottom: 5px;
      }
    }

    .confirm-diff-info {
      color: #ffffff;
      font-size: 12px;
      line-height: 15px;
      &.disabled {
        color: $gray-2;
      }
    }
  }

  &.pop-delete-section {
    // Placement overrides to align 'Confirm' link directly above IconTrash on SectionEditor
    margin-left: -60px;
    z-index: 1000;
    .arrow {
      margin-left: 50px;
    }

    .popover-content {
      padding: 3px 8px;
      font-size: 1.2rem;
      font-weight: 600;
      color: $white;
      .confirm {
        margin-left: 5px;
        cursor: pointer;
        color: lighten($alert, 10%);
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.pop-link {
    margin-top: 5px;
    .popover-content {
      padding: 3px 8px;
      font-size: 1.2rem;
      font-weight: 600;
      color: $white;
      .open-link {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  //protected variable viewer
  .protected {
    position: relative;
    input {
      padding-right: 25px; //make room for the secure lock icon
    }
    svg {
      position: absolute;
      pointer-events: none;
      right: 0px;
      top: 2px;
      padding: 5px;
      path {
        color: $black20;
        fill: $black20;
      }
    }
    &.editable {
      input:focus {
        border-color: $done;
      }
      svg path {
        color: $done;
        fill: $done;
      }
    }
    &.readonly input {
      color: $black40;
      &:focus {
        outline: none;
        border-color: $black20;
        box-shadow: none;
      }
    }
    .dmp-loader {
      position: absolute;
      left: 8px;
      top: 10px;
    }
    .note {
      width: 100%;
      display: block;
      font-size: 1.1rem;
      line-height: 1.3;
      font-style: italic;
      color: $black40;
      margin: 10px 0 5px;
    }
  }

  &#pop-deal-status {
    background-color: rgba($outlaw-dark, 0.95);
    border-color: rgba($outlaw-dark, 0.95);

    &.bottom {
      margin-top: 20px;
    }

    .popover-title {
      color: white;
      @extend .txt_headline_2;
      line-height: 1.1;
      padding: 15px 15px 0px 15px;
      font-size: 14px;
      font-weight: 600;
    }
    .popover-content {
      @extend .txt_body;
      padding-bottom: 15px;
      color: rgba($white, 75%);
    }
    .arrow {
      &:after {
        border-top-color: rgba($outlaw-dark, 0.95);
        border-bottom-color: rgba($outlaw-dark, 0.95);
      }
    }
    //action button if present
    .action {
      font-weight: 700;
      margin-top: 15px;
      cursor: pointer;
      width: 100%;
      border-radius: 5px;
      border: 1.5px solid $white;
      text-align: center;
      padding: 8px 16px;
      font-size: 13px;
      &:hover {
        opacity: 0.8;
      }
    }
    .diff-review {
      display: flex;
      flex-direction: row;
      .action {
        flex: 1;
      }
      .approve {
        margin-right: 8px;
      }
      .reject {
        margin-left: 8px;
      }
    }
    &.pop-diff .popover-content {
      // padding-bottom: 15px;
    }
  }

  &.dark {
    background-color: rgba($outlaw-dark, 0.95);
    border-color: rgba($outlaw-dark, 0.95);
    .popover-title,
    .popover-content {
      color: $white;
    }
    .arrow {
      display: block;
      &:after {
        border-top-color: rgba($outlaw-dark, 0.95);
        border-bottom-color: rgba($outlaw-dark, 0.95);
      }
    }
  }
  &.small {
    padding: 15px;
    width: 220px;
    .popover-title {
      margin: 0;
      display: block;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.1rem;
      padding: 0 0 5px;
    }
    .popover-content {
      font-size: 1.2rem;
    }
  }

  //used on variables
  .close-popover {
    width: 12px;
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0.4;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .popover-title {
    //padding:15px 45px 15px 15px;
    @extend .txt_headline_6;
    // color: $black60;
    background-color: transparent;
    border-bottom: 0;
  }
  .popover-content {
    //padding:0 15px 15px 15px;
    @extend .txt_body;

    .actions {
      width: 100%;
      display: block;
      position: relative;
      right: 0;
      text-align: right;
    }
    //today link in variable viewer
    .today-link {
      position: absolute;
      font-size: 1.2rem;
      text-decoration: underline;
      color: $black100;
      cursor: pointer;
      z-index: 100; //make sure this is on top of the stupid 100% width .actions div
      margin-top: 7px;
    }
  }

  &.popover-tagger {
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    .error {
      color: $alert;
    }

    .available-tags {
      max-height: 180px;
      overflow-x: hidden;
      .deal-tag {
        display: flex;
        align-items: center;
        border: 1px solid $black10;
        height: 30px;
        padding: 0 10px;
        &:not(:last-child) {
          margin-bottom: 5px;
        }
        color: $black20;
        font-weight: 700;
        font-size: 1.2rem;
        cursor: pointer;

        .tag-meta {
          display: flex;
          overflow: hidden;

          svg.tag-icon {
            align-self: center;
            transform: rotate(-45deg);
            width: 14px;
            margin: -2px 8px 0 0;
            path {
              fill: $black20;
            }
            flex-shrink: 0;
          }

          .tag-name {
            text-transform: capitalize;
            overflow: hidden;
          }

          .tag-count {
            margin: 0 5px;
          }
        }

        .spacer {
          flex: 1;
        }

        svg.check {
          path {
            fill: $outlaw;
          }
        }

        &.active {
          color: $black60;
          svg.tag path {
            fill: $black60;
          }
          border-color: $outlaw;
        }
        &:not(.active):not(.deleting):hover {
          border-color: $black40;
        }

        &.managing {
          cursor: default;
          .tag-actions {
            display: flex;
            svg {
              cursor: pointer;
              width: 14px;
              &:not(:last-child) {
                margin-right: 5px;
              }
              path {
                fill: $black20;
              }
              &:hover path {
                fill: $black100;
              }
            }
          }
        }

        .tag-actions .check {
          display: flex;
        }

        &.deleting {
          color: $black60;
          svg.tag path {
            fill: $alert;
          }
          display: block;
          height: auto;
          padding: 5px 10px;
          border-color: $alert;
          .delete-confirm {
            .instructions {
              font-weight: normal;
              padding: 5px 0;
            }
            .actions {
              margin-top: 0;
              button.cancel {
                flex: 1;
                text-align: left;
              }
              button.delete {
                color: $alert;
              }
            }
          }
        }
      }
    }
    .actions {
      margin-top: 5px;
      display: flex;
      .spacer {
        flex: 1;
      }
      button {
        color: $black80;
        font-weight: 700;
        padding: 0;
        margin: 0;
        &.add {
          color: $outlaw;
        }
      }
    }
  }
}

.teammate-selector {
  border: 1px solid $black10;
  border-radius: 1px;
  background: $white;
  display: flex;
  flex-direction: column;
  width: 180px;

  .scroll {
    flex: 1;
    overflow-y: auto;
    border-top: 1px solid $black10;
  }
  .teammates-list {
    width: inherit;
  }

  .message {
    padding: 12px;

    .txt {
      text-align: center;
      font-size: 1.2rem;
      background: $gray-5;
      padding: 12px;
    }
  }
}

.teammate {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  width: inherit;

  .initials {
    flex-basis: 22px;
    flex-grow: 0;
    flex-shrink: 0;
    @include initialCircle($black40);
    width: auto;
    margin-right: 8px;
  }
  .name-email {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .name,
    .email {
      max-width: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .name {
      font-weight: 600;
      font-size: 1.3rem;
      color: $black80;
    }
    .email {
      width: inherit;
      font-weight: normal;
      font-size: 1.1rem;
      font-weight: 300;
      color: $black40;
    }
  }

  &.existing,
  &.disabled,
  &.adding-teammate {
    .initials {
      background: $black10;
    }
    .name {
      color: $black20;
    }
    .email {
      color: $black20;
    }
    .actions {
      visibility: hidden;
    }

    cursor: default;

    .dmp-loader {
      margin: 5px 0 0 5px;
    }
  }
  &.disabled {
    .reason {
      font-size: 1.1rem;
      font-style: italic;
      color: $black20;
    }
  }

  &.simple {
    cursor: pointer;
    &:hover {
      background: $black3;
      .initials {
        background: $black60;
      }
      .name,
      .email {
        color: $black80;
      }
    }
  }
  .role {
    width: 90px;
  }

  // For hover state of disabled users
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.deal-user-block,
.deal-party-block {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 5px;

  $topline-min-height: 22px;

  .topline {
    max-width: 100%;
    min-height: $topline-min-height;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .name {
      flex: 1;
      min-height: $topline-min-height;
      color: $black80;
      font-weight: 600;
      font-size: 1.3rem;

      &.failed {
        color: $brand-danger !important;
      }
    }

    .topline-actions {
      display: block;
      height: $topline-min-height;
      width: 40px;
      position: relative;

      .#{$dmp-checkbox} {
        margin-top: 2px;
        height: $topline-min-height;

        label {
          height: $topline-min-height;
        }
      }
    }
  }

  .info {
    .title-org,
    .status {
      color: $black60;
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.3rem;
      margin-bottom: 5px;

      &.failed {
        color: $brand-danger;
      }
    }
  }

  a.invite {
    // color: $outlaw;
    color: $black80;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1.3rem;

    &.failed {
      color: $brand-danger;
    }
  }
}

// Big material-style [+] button used for Add Contract
.material-plus {
  padding: 5px;
  background: $outlaw;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mediaquery-medium {
    width: 50px;
    height: 50px;
  }

  svg {
    width: 20px;
    height: 20px;
    path,
    rect {
      fill: $white;
    }
  }
  &:hover {
    background: darken($outlaw, 2%);
  }
}

.template-link {
  display: flex;
  align-items: center;
  svg {
    path,
    rect {
      fill: $black40;
    }
    margin-right: 5px;
  }
  a {
    font-size: 1.2rem;
    color: $black40;
    &:hover {
      color: $black30;
    }
  }
}

.pages-render-view {
  &.hasError {
    background: #fce8e6;
    color: $color-red;
  }

  .pages-render-view-message {
    padding-left: 5px;
    font-weight: 300;
  }
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  @extend .font_sans;
  border-radius: 2px;
  margin-bottom: 24px;
  margin-top: 1px;
  padding: 3px;

  &.noPages {
    color: $black40;
    background: $gray-5;
  }
  &.paged {
    color: $color-blue;
    background: #e8f1fe;

    svg {
      path {
        fill: $color-blue;
      }
    }
  }

  &.hasError {
    svg {
      path {
        fill: $color-red;
      }
    }
  }

  svg {
    height: 15px;
    min-width: 15px;
    margin: 1px 3px 3px 1px;
    path {
      fill: $gray-2;
    }
    margin-right: 5px;
  }
}

.conditions-view {
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  @extend .font_sans;
  color: $black80;
  margin-top: 1px;
  padding: 3px;
  background: #fef9ef;
  border-radius: 2px;
  cursor: default;

  &.hasError {
    & > svg {
      path {
        fill: $color-red;
      }
    }
  }

  & > svg {
    height: 15px;
    min-width: 15px;
    margin: 1px 3px 3px 1px;
    path {
      fill: orange;
    }
    margin-right: 5px;
  }

  .each-condition {
    &.hasError {
      .variable,
      .operator,
      .value {
        color: $color-red !important;
      }

      .variable {
        font-weight: bold;
      }
    }

    .variable {
      color: orange;

      &.error {
        color: $alert;
      }
    }
    .operator {
      color: $gray-1;
      font-weight: 400;
    }
    .value {
      color: $gray-1;
      font-weight: 500;

      &.error {
        svg {
          width: 1.25em;
          height: 1.25em;
          fill: $alert;
          margin-bottom: -0.25em;
          margin-right: 0.25em;
        }
      }
    }
    .conditional-operator {
      color: #67a0f8;
    }
  }

  &.editable {
    &:hover {
      color: $black80;
      // path { fill: $outlaw; }
    }
    .each-condition {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

/* Utility from Bootstrap 4 used to hide elements easily */
.d-none {
  display: none;
}

a {
  cursor: pointer;
}

.default {
  cursor: default;
}
