/*
.section-columns <- wrapper div when sections are in columns
.source-section
  .source words
    .source-number
    .source-text
    .section-menu <- used on scope, payment and source
  .source-toggle
  .activity-anchor
*/
.ai-highlight {
  background-color: #f7f0fc;
}

//hover state showing section is editable
.is-editable {
  &:not(.editing-section):not(.locked):not(.stale):not(.locked-step) .source-words {
    @include mediaquery-medium {
      cursor: pointer;
    }
  }
  // used on .source-section, payment-section and scope-section
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    pointer-events: none;
    border-radius: 2px;
    border: 1px solid transparent;
    box-shadow: 0 0px 0px 0 rgba($black100, 0);
    transition: box-shadow 0.2s linear, bottom 0.1s ease-out, border-color 0.2s;
    top: -5px;
    left: -10px;
    bottom: -6px;
    right: -10px;
    @include mediaquery-medium {
      right: -10px;
    }
  }

  &.is-empty {
    &::before {
      border-style: dashed;
      border-color: $gray-3;
    }

    &.editing-section,
    &:hover {
      &::before {
        border-color: $gray-3;
        border-style: solid;
      }
    }

    .empty-text {
      display: inline;
      color: $gray-3;
      line-height: 1;
    }
  }

  &.locked {
    &:before {
      background: $black5;
      border: 1px solid $black10;
    }
    cursor: not-allowed;
    pointer-events: all;

    #dd-lock {
      position: absolute;
      right: -10px;
      top: -2px;
      svg {
        width: 16px;
        height: 16px;
        path,
        rect {
          fill: $gray-2;
        }
        &:hover {
          path,
          rect {
            fill: $gray-dark;
          }
        }
      }
    }

    .source-number,
    .source-text {
      .diff.added,
      .diff.removed {
        color: $black60;
        text-decoration-color: $black40;
      }
    }

    .marker {
      display: none;
    }
  }

  &.stale {
    .source-number,
    .source-text {
      color: $black60;
    }
  }

  &.locked-step:hover {
    z-index: 1;
    .section-menu {
      z-index: 100;
      .dmp-dd-toggle {
        opacity: 1;
      }
    }
  }

  //default state during a deal for editable sections
  &:hover:not(.locked):not(.locked-step) {
    z-index: 1;
    &:before {
      //box behind
      border-color: $gray-3;
      box-shadow: 0 2px 2px 0 rgba($black100, 0.1);
    }
    .section-menu {
      z-index: 100;
      .dmp-dd-toggle {
        opacity: 1;
      }
    }
  }
}

//if section is being edited show this
.editing-section {
  z-index: 2; // remain higher than hover during edit mode

  // Add bottom padding to account for editor strip, which is absolutely positioned
  &:not(.payment-section, .clause-lookup) {
    padding-bottom: 45px;
  }

  &:before {
    background-color: rgba($white, 0.75);
    border: 1px solid $black3;
    box-shadow: 0 2px 3px 0 rgba($black100, 0.2) !important; //override hover
  }

  .source-text {
    // text align back to left for edit mode
    cursor: text;
    text-align: left;
    position: relative;
    .editing-title {
      font-weight: 700;
    }
  }

  &.unordered {
    .source-text {
      &:before {
        left: -10px;
      }
    }
  }

  .editor-actions-section {
    width: calc(100% + 20px);
    position: absolute;
    left: -10px;
    border-top: 1px solid $gray-3;
    margin-top: 9px;
    height: auto;
    justify-content: space-between;
    align-items: center;
  }

  //[update] and [cancel] buttons
  .editor-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 99%;
    margin-left: 1px;
    background-color: rgba($white, 0.75);

    .left-side {
      // [x]track changes [x]notify others [delete] ([toggle indent])
      display: inherit;
      flex-direction: inherit;
      align-items: inherit;
      height: 40px;
      margin-left: -1px;

      // this wrapper is used because [x]checkboxes and [buttons]
      // in .editor-actions are all different sizes and we need a consistent height
      // for the border-right
      .editor-item {
        display: inherit;
        align-items: inherit;
        justify-content: center;
        height: inherit;
        padding: 0 5px 0 10px;
        flex-grow: 1;
        @include mediaquery-medium {
          border-right: 1px solid $gray-3;
          padding: 0 10px;
        }
      }

      .#{$dmp-checkbox} {
        //[x] track changes [x]notify others
        @extend .font_sans;
        label {
          font-size: 1.2rem;
          font-weight: 500;
          color: $black90;
        }
      }
    }

    .right-side {
      // [spinner] [cancel] [save]
      display: flex;
      align-items: center;
      padding-right: 4px;
      padding-left: 4px;
      height: 40px;

      .btn {
        font-weight: 500;
        font-size: 1.2rem;
      }
    }
  }

  .editor-message-section {
    height: auto;
    display: flex;
    background-color: #252f3d;
    font-size: 1.1rem;
    border-radius: 0 0 3px 3px;
    @extend .font_sans;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);

    .editor-message {
      flex: 1;
      padding: 5px 15px;
      &.error {
        color: $alert;
      }
      &.pending {
        text-align: right;
        color: $black40;
        animation: fadeIn 0.1s;
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
      &.info {
        color: $black40;
        .version-history {
          color: $black40;
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .editor-message-save {
        color: $black30;
        font-weight: 700;
      }
    }
  }
}

.has-changes {
  &:not(.payment-section, .clause-lookup) {
    padding-bottom: 65px;
  }
}

.trash {
  padding: 0;
  svg {
    width: 14px;
    height: 14px;
  }
  &:hover {
    svg {
      opacity: 0.5;
    }
  }
}

.section-columns {
  &.page-break {
    @include pageBreak();
  }

  .editing-section {
    padding-bottom: 0px;
    .editor-actions-section {
      position: relative;
      flex-wrap: wrap;
      .left-side {
        border-bottom: 1px solid $black10;
        flex-grow: 1;
      }
      .right-side {
        flex-grow: 1;
        .cancel {
          margin-left: auto;
        }
      }
    }
  }

  .editing-section::before {
    margin-bottom: 5px;
  }

  @include mediaquery-medium {
    display: flex;
    align-items: flex-start;

    .source-section {
      max-width: 50%;
      flex: 1;
    }
  }

  @include mediaquery-large {
    .marker {
      left: -40px !important;
    }
  }
}

.footnotes {
  margin-top: 10px;

  .footnote-content {
    display: flex;
    flex-direction: row;
    //flex: 1;

    .footnote-order {
      vertical-align: super; /* 2 */
      margin-right: 5px;
    }
    .footnote-body {
      text-align: left;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .deleting {
      text-decoration: line-through;
    }
  }
}

.section-caption {
  display: flex;
  position: relative;

  &.page-break,
  &.header-footer-break {
    @include pageBreak();
  }

  .editor-actions-section {
    bottom: -5px;
  }
  .caption-lhs,
  .caption-rhs,
  .third-column {
    flex: 1;
  }

  // Ensure that containing div is full height for borders/padding
  .section-editor,
  .source-section {
    height: 100%;
  }

  .caption-measure {
    position: absolute;
    z-index: -1;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
  }

  .caption-divider {
    height: 0;
    overflow-y: visible;
  }

  .editor-actions-section {
    .left-side {
      .editor-item-trash {
        border-right: 0px solid $black10;
        padding: 0 10px;
      }
    }
  }
}

/*.template-header-footer-container {
  border: 1px solid gray;
  padding: 5px -5px 12px 12px;
}*/

.section-two-columns-container {
  .section-two-columns {
    display: flex;
    position: relative;

    &.page-break,
    &.header-footer-break {
      @include pageBreak();
    }

    .editor-actions-section {
      bottom: -5px;
    }

    .sub-section-columns {
      flex: 1;
    }

    // Ensure that containing div is full height for borders/padding
    // .section-editor,
    // .source-section {
    //   height: 100%;
    // }

    .two-columns-measure {
      position: absolute;
      z-index: -1;
      display: flex;
      flex-direction: column;
      visibility: hidden;
      opacity: 0;
    }

    .editor-actions-section {
      .left-side {
        .editor-item-trash {
          border-right: 0px solid $black10;
          padding: 0 10px;
        }
      }
    }
  }
}

.source-section,
.list-section {
  //we need to use this table/table-cell display method so that no margin needs to be defined on the source-text
  //this way when the number is not present, the source-text moves over to take full width
  display: table;
  width: 100%;
  position: relative;

  &:after {
    @include mediaquery-medium {
      //only show on desktop
      content: '';
      width: 80px;
      height: 100%;
      position: absolute;
      top: 0px;
      right: -80px;
    }
  }

  &.unordered {
    //fixes hover state background on top-level unordered sections
    .source-text:before {
      left: -10px;
    }
  }

  // marker on the left hand side of section
  .marker {
    position: absolute;
    left: -20px;
    top: 6px;
    min-width: 24px;
    transform: scale(0.3);
    @include mediaquery-medium {
      transform: scale(0.75);
      left: -32px;
      top: 1px;
    }
    .deal-status-review,
    .deal-status-proposed,
    .deal-status-alert {
      cursor: pointer;
    }
  }
}

.list-section {
  .items-container {
    position: relative;
  }
  .dmp-loader {
    margin-bottom: 16px;
    margin-top: 6px;
  }
  .editor-actions-section {
    bottom: auto;
  }

  .ai-block-loader {
    display: flex;
    color: $gray-2;

    .dmp-loader {
      margin-right: 5px;
      margin-top: 2px;
    }
  }
}

.source-words,
.scope-words {
  min-height: 0;
  display: flex;
  width: 100%;

  .aiedited {
    color: $outlaw !important;
    background-color: #5a1fa3 !important;
    white-space: pre-wrap;
    background: linear-gradient(to bottom right, #c9aaee 50%, transparent 50%) top left/4px 4px,
      linear-gradient(to bottom right, transparent 50%, #c9aaee 50%) bottom right/4px 4px,
      linear-gradient(0deg, #c9aaee 1px, transparent 1px), linear-gradient(180deg, #c9aaee 1px, transparent 1px) !important;
    background-repeat: no-repeat !important;

    &.removed {
      text-decoration: line-through !important;
      text-decoration-color: #c9aaee !important;
      text-decoration-thickness: 2px !important;
    }
  }

  .diff {
    white-space: pre-wrap;
    &.added {
      color: $color-green;
      background: linear-gradient(to bottom right, $color-green 50%, transparent 50%) top left/4px 4px,
        linear-gradient(to bottom right, transparent 50%, $color-green 50%) bottom right/4px 4px,
        linear-gradient(0deg, $color-green 1px, transparent 1px),
        linear-gradient(180deg, $color-green 1px, transparent 1px);
      background-repeat: no-repeat;
      background-color: #dff7ee;

      &.can-review:hover {
        background-color: rgba($done, 0.25);
        cursor: pointer;
      }
    }

    &.removed {
      color: $alert;
      text-decoration: line-through;
      text-decoration-color: $alert;
      text-decoration-thickness: 2px;
      background-color: rgba($alert, 0.1);
      border-color: rgba($alert, 0.1);
      &.can-review:hover {
        background-color: rgba($alert, 0.2);
        cursor: pointer;
      }
    }

    &.can-revert.pending,
    &.can-revert.pending:hover {
      color: $black40;
      cursor: pointer;
      background-color: rgba($black40, 0.1);
      text-decoration-color: $black40;
    }
  }

  // Fix centered/right DraftJS placeholders while editing
  &.align-center {
    @include centerPH();
  }
  &.align-right {
    @include rightPH();
  }
}

.source-number {
  flex-shrink: 0;
  margin-right: 8px;
}

.source-text {
  flex-grow: 1;
  position: relative; //ensure that comment anchor shows in correct place

  //styling now rendered in inline spans with styles instead of tags!
  .bold {
    font-weight: 600;
  }
  .underline {
    text-decoration: underline;
  }
  .italic {
    font-style: italic;
  }
  .section-title-placeholder {
    font-weight: bold;
  }
  .cl-lookup-text {
    font-style: italic;
    font-weight: 400;
  }
}

.section-menu {
  @extend .font_sans;
  position: absolute;
  right: 15px;
  top: 0px;

  @include mediaquery-medium {
    top: -2px;
    right: -6px;
  }

  .dmp-dd-toggle {
    opacity: 0;
  }

  ul.clear-sig,
  ul.ready-check,
  ul.need-login {
    padding-top: 0;
  }
  ul.need-login {
    padding-bottom: 0;
    border: 0;
  }

  .disabled {
    opacity: 0.3;
  }
  ul {
    max-height: inherit;
  }
}

.activity-anchor {
  //comment icon
  position: absolute;
  height: 32px;
  width: 32px;
  z-index: 10;
  cursor: pointer;
  display: none;
  transition: all 0.2s ease-in;
  right: -15px;
  top: -5px;
  @include mediaquery-medium {
    border: 1px solid transparent;
    border-radius: 50%;
    right: -50px;
    top: -7px;
  }

  svg {
    position: absolute;
    top: 5px;
    left: 5px;
    path {
      fill: $black40;
    }
  }

  &:hover {
    @include mediaquery-medium {
      border-color: $black10;
      background-color: $white; //only show fill on desktop
      box-shadow: 0 2px 2px rgba($black100, 0.3);
    }
    &.agreed,
    &.none {
      svg path {
        fill: $black60;
      }
    }
  }

  &.agreed {
    // display: block;
    // border-color: $black10;
    svg path {
      fill: $black20;
    }
    &.force-show {
      display: block;
      &.showing {
        border-color: $black20;
      }
    }
  }

  &.discuss {
    display: block;
    border-color: $black10;
    svg path {
      fill: $discuss;
    }
  }

  &.showing {
    //visible
    display: block;
    &.none {
      border-color: $black20;
      svg path {
        fill: $black40;
      }
    }
    &.review {
      svg path {
        fill: $discuss;
      }
    }
    &.agreed {
      svg path {
        fill: $black100;
      }
    }
  }
}

.source-view {
  //for deal footer, e.g., "Confidential and Proprietary"
  .deal-license {
    width: 100%;
    text-align: center;

    //on desktop pull it into the page "margin" without adding height
    @include mediaquery-medium {
      margin-top: 40px;
    }
  }
}

// Header styles are used in common across both Draft and Flow
.header-h1 {
  @include centerPH();
  .public-DraftEditorPlaceholder-root {
    font-size: 30px;
  }
}

.header-h2 {
  @include centerPH();
  .public-DraftEditorPlaceholder-root {
    font-size: 22px;
    line-height: 26px;
  }
}

.header-h3 {
  @include centerPH();
  .public-DraftEditorPlaceholder-root {
    font-size: 15px;
    line-height: 22px;
  }
}

.header-h4 {
  @include centerPH();
}
